<template>
	<div class="body">
		<Stars></Stars>
		<div class="only-form">
			<span class="only-back-home" title="回主页" @click="backhome()"><img src="/img/kasuie.jpg" ></span>
			<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" @submit.native.prevent class="demo-ruleForm">
			  <img v-lazy="'https://i.loli.net/2021/01/30/ITEeli4Du2zwcXf.png'" id="miao-tips" v-popover:popover>
			  <el-popover
			      ref="popover"
			      placement="top-start"
			      width="250"
			      trigger="manual"
				  popper-class='miao-popper'
				  v-model="show"
			      content="只有一个人能打开的哦(´ﾟωﾟ｀) !">
			  </el-popover>
			  <el-form-item prop="pass">
			    <el-input type="password" v-model="ruleForm.pass" autocomplete="off" @focus='imgFocus()' @blur='imgBlur()'></el-input>
			  </el-form-item>
			  <el-button round @click="submitForm('ruleForm')" disabled>开始连接</el-button>
			</el-form>
		</div>
	</div>
</template>

<script>
	import Stars from '@/components/Stars.vue'
	export default {
		components: {
			Stars
		},
		data() {
			return {
				show: false,
				ruleForm: {
					pass: '',
				},
				rules: {
					pass: [
						{ required: true, message: '请输入密码', trigger: 'change' }
				    ],
				},
			}
		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.loginQc()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			loginQc(){
				let that = this
				this.axios.post('loginQc',that.ruleForm.pass).then(function(results){
					if(results.data==1){
						localStorage.setItem('qc','ckz')
						that.$notify({
							title: '成功',
							message: '连接成功，正在进入...',
							type: 'success'
						});
						that.$router.push({ path: '/qc' })
					}else if(results.data==0){
						localStorage.setItem('qc','qx')
						that.$notify({
							title: '成功',
							message: '连接成功，正在进入...',
							type: 'success'
						});
						that.$router.push({ path: '/qc' })
					}else{
						that.$notify.info({
						    title: '连接失败',
						    message: '密码错误哦~'
						});
					}
				},function(err){
					console.log(err);
				})
			},
			imgFocus(){
				this.show = true
				setTimeout(function() {
					this.show = false
				}, 1000);
			},
			imgBlur(){
				this.show = false
			},
			backhome(){
				this.$router.push({ path: '/' })
			}
		},
		mounted() {
			this.$store.commit('showLoading')
		}
	}
</script>

<style>
	
	#bottom-bar{
		margin: 0 !important;
	}
	.only-form{
		position: relative;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.5);
	}
	.only-form .el-form{
		position: relative;
		top: 36%;
		width: 40em;
		margin: 0 auto;
	}
	.only-form .el-form .el-input__inner{
		background: unset;
	}
	.only-form .el-form img{
		position: relative;
		width: 12em;
		top: 1.4em;
		z-index: 1;
	}
	.only-form .el-form .el-button{
		background: unset;
	}
	.only-form .only-back-home{
		position: absolute;
		left: 2em;
		top: 2em;
		cursor: pointer;
	}
	.only-form .only-back-home img{
		width: 2em;
		border-radius: 50%;
	}
	.miao-popper{
		background: #b4dff0;
		border: 1px solid #d0e4ec;
		color: #9e6682;
	}
	@media screen and (max-width: 770px) {
		.only-form .el-form{
			width: 95%;
		}
	}
</style>
